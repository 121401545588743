import { createContext } from 'react';
// PNG Logos
import BRQdigital from 'images/logo-brq-digital.webp';
import GrupoSiti from 'images/logo-grupo-siti.webp';
import Linx from 'images/logo-linx.webp';
// Webp Logos
import BRQdigital_png from 'images/logo-brq-digital.png';
import GrupoSiti_png from 'images/logo-grupo-siti.png';
import Linx_png from 'images/logo-linx.png';
// SVGS Logos
import Stone from 'images/logo-stone.svg';
import Totvs from 'images/logo-totvs.svg'
import Accenture from 'images/logo-accenture.svg';
import Huawei from 'images/logo-huawei.svg';
import RobertHalf from 'images/logo-robert-half.svg';
import Vagas from 'images/logo-vagas-rebrand.svg';
const { VAGAS_URL } = require('../../env-config');

const companiesLogos = [
  {
    name: 'Stone',
    image: Stone,
    link: 'https://trabalheconosco.vagas.com.br/stone/oportunidades',
  },
  {
    name: 'BRQ Digital',
    image: BRQdigital,
    imageSafari: BRQdigital_png,
    link: 'https://trabalheconosco.vagas.com.br/brqdigital',
  },
  {
    name: 'Totvs',
    image: Totvs,
    link: `${VAGAS_URL}/empregos/totvs`,
  },

  {
    name: 'Grupo Siti',
    image: GrupoSiti,
    imageSafari: GrupoSiti_png,
    link: 'https://trabalheconosco.vagas.com.br/grupo-siti',
  },
  {
    name: 'Linx',
    image: Linx,
    imageSafari: Linx_png,
    link: 'https://trabalheconosco.vagas.com.br/linx',
  },
  {
    name: 'Accenture',
    image: Accenture,
    link: `${VAGAS_URL}/empregos/accenture`,
  },
  {
    name: 'Huawei',
    image: Huawei,
    link: `${VAGAS_URL}/empregos/huawei`,
  },
  {
    name: 'Robert Half',
    image: RobertHalf,
    link: `${VAGAS_URL}/empregos/robert-half`,
  },
  {
    name: 'Vagas',
    image: Vagas,
    link: 'https://trabalheconosco.vagas.com.br/vagas',
  },
];

const companiesContext = createContext(companiesLogos);

export default companiesContext;
