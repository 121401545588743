import { createContext } from 'react';
const { VAGAS_URL } = require('../../env-config');

const topLinks = [
  { url: 'https://trabalheconosco.vagas.com.br/vagas', type: "institutional", label: 'Institucional' },
  { url: VAGAS_URL, type: "candidates", label: 'Candidatos' },
  { url: 'https://forbusiness.vagas.com.br/', type: "forbusiness", label: 'Empresas' },
];

const headerContext = createContext(topLinks);

export default headerContext;