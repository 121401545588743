import { createContext } from 'react';
import android from 'images/download_android.svg';
import ios from 'images/download_ios.svg';
const { VAGAS_URL, INFO_URL } = require('../../env-config');

const institutional = {
  mainTitle: 'Institucional',
  mainUrl: 'https://trabalheconosco.vagas.com.br/vagas',
  links: {
    who_we_are: {
      title: 'Quem somos',
      url: 'https://trabalheconosco.vagas.com.br/vagas',
    },
    horizontal_management: {
      title: 'Gestão horizontal',
      url: 'https://trabalheconosco.vagas.com.br/vagas/gestao-horizontal',
    },
    our_business: {
      title: 'Nosso negócio',
      url: 'https://trabalheconosco.vagas.com.br/vagas/nosso-negocio',
    },
    diversity: {
      title: 'Diversidade',
      url: 'https://trabalheconosco.vagas.com.br/vagas/diversidade-inclusao',
    },
    work_with_us: {
      title: 'Trabalhe conosco',
      url: 'https://trabalheconosco.vagas.com.br/vagas',
    },
    advertisement: {
      title: 'Publicidade',
      url: `${VAGAS_URL}/publicidade`,
    },
    press: {
      title: 'Imprensa',
      url: `${INFO_URL}/imprensa`,
    },
  },
};

const companies = {
  mainTitle: 'Empresas',
  mainUrl: 'https://forbusiness.vagas.com.br/',
  links: {
    vagas_for_business: {
      title: 'VAGAS for business',
      url: 'https://forbusiness.vagas.com.br/',
    },
    ats_platform: {
      title: 'Ferramenta de R&S',
      url: 'https://forbusiness.vagas.com.br/ferramenta-recrutamento-e-selecao',
    },
    solucoes: {
      title: 'Soluções',
      url: 'https://forbusiness.vagas.com.br/solucoes-de-recrutamento-e-selecao/',
    },
    behavioral_assesment: {
      title: 'Avaliação Comportamental',
      url: 'https://forbusiness.vagas.com.br/avaliacao-comportamental/',
    },
    careers_website: {
      title: 'Portal de Carreiras',
      url: 'https://forbusiness.vagas.com.br/portal-de-carreiras/',
    },
    video_interview: {
      title: 'Videoentrevista',
      url: 'https://forbusiness.vagas.com.br/videoentrevista/',
    },
    faq: {
      title: 'Dúvidas Frequentes',
      url: 'https://forbusiness.vagas.com.br/duvidas-frequentes/',
    },
    clients: {
      title: 'Nossos clientes',
      url: `${VAGAS_URL}/clientes-vagas`,
    },
    blog: {
      title: 'Blog',
      url: 'https://forbusiness.vagas.com.br/blog/',
    },
    contact: {
      title: 'Contato',
      url: 'https://forbusiness.vagas.com.br/#formulario_ancora',
    },
    access_to_vagas_redes: {
      title: 'Acesso ao VAGAS Redes',
      url: 'https://redes.vagas.com.br/',
    },
  },
};

const applicants = {
  mainTitle: 'Candidatos',
  mainUrl: `${VAGAS_URL}/`,
  links: {
    what_does_each_job_position_do: {
      title: 'O que faz cada cargo?',
      url: `${VAGAS_URL}/cargo`,
    },
    job_search: {
      title: 'Pesquisa de vagas',
      url: `${VAGAS_URL}/`,
    },
    app_vagas: {
      title: 'Aplicativo VAGAS de Emprego',
      url: `${VAGAS_URL}/aplicativo/`,
    },
    jobs_for_health_professionals: {
      title: 'Vagas para profissionais da saúde',
      url: 'https://info.vagas.com.br/coronavirus-emprego-hospitais/',
    },
    jobs_for_technology: {
      title: 'Vagas de tecnologia',
      url: 'https://tecnologia.vagas.com.br/',
    },
    free_services_to_candidates: {
      title: 'Serviços gratuitos para candidatos',
      url: `${VAGAS_URL}/servicos-gratuitos-candidatos`,
    },
    companies_hiring: {
      title: 'Empresas contratando',
      url: `${VAGAS_URL}/empresas-contratando`,
    },
    vagas_com_br: {
      title: 'Como funciona',
      url: `${VAGAS_URL}/como-funciona`,
    },
    vagas_profissoes: {
      title: 'VAGAS Profissões',
      url: `${VAGAS_URL}/profissoes/`,
    },
    mapa_vagas_de_carreira: {
      title: 'Mapa VAGAS de Carreiras',
      url: `${VAGAS_URL}/mapa-de-carreiras/sobre`,
    },
    help: {
      title: 'Ajuda',
      url: `${VAGAS_URL}/ajuda-candidatos/`,
    },
    security_and_privacy: {
      title: 'Segurança e Privacidade',
      url: `${VAGAS_URL}/seguranca-privacidade/`,
    },
  },
};

const appVagas = {
  mainTitle: 'Aplicativo Vagas',
  mainUrl: '#',
  links: {
    vagas_forum: {
      title: 'App Android',
      url: 'https://play.google.com/store/apps/details?id=br.com.apps.jaya.vagas&referrer=utm_source%3Dvagas_rodape%26utm_medium%3Dselo',
      img: android,
      imgAlt: 'Disponível Google Play',
      anotherTab: true,
    },
    artevagas: {
      title: 'App IOS',
      url: 'https://apps.apple.com/br/app/vagas-de-emprego/id1230639345',
      img: ios,
      imgAlt: 'Baixar na App Store',
      anotherTab: true,
    },
  },
};

const footerLinks = [
  institutional,
  companies,
  applicants,
  appVagas,
];

const footerContext = createContext(footerLinks);

export default footerContext;
