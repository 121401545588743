import { createContext } from 'react';

const networks = {
  facebook: {
    title: 'Facebook',
    url: 'http://facebook.com/VAGAScom/',
    icon: 'facebook',
  },
  instagram: {
    title: 'Instagram',
    url: 'https://www.instagram.com/vagascom/?hl=pt-br',
    icon: 'instagram',
  },
  twitter: {
    title: 'Twitter',
    url: 'http://twitter.com/vagas',
    icon: 'twitter',
  },
  youtube: {
    title: 'Youtube',
    url: 'https://www.youtube.com/user/VAGAStecnologia',
    icon: 'youtube',
  },
  linkedin: {
    title: 'Linkedin',
    url: 'https://www.linkedin.com/company/vagascom',
    icon: 'linkedin',
  },
};

const socialMediaContext = createContext(networks);

export default socialMediaContext;
