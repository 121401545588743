let DOMAIN = '';
let ENV = 'dev';
let SERVER_PORT = '';
let VACANCIES_API_BASE_URL = '';
let TEC_VAGAS_URL = '';
let VAGAS_URL = '';
let INFO_URL = '';
let DICAPRIO_VACANCIES_API_BASE_URL = '';
let PRIVALLY_PID = '';
let GTM_ID = '';

if (process.env.GATSBY_ENV) {
  ENV = process.env.GATSBY_ENV;
}

switch (ENV) {
  case 'prod':
    DOMAIN = 'tecnologia.vagas.com.br';
    SERVER_PORT = '9001';
    VACANCIES_API_BASE_URL = 'http://pesquisas.vagas-prod.net/v1';
    TEC_VAGAS_URL = 'https://tecnologia.vagas.com.br';
    VAGAS_URL = 'https://www.vagas.com.br';
    INFO_URL = 'https://info.vagas.com.br';
    PRIVALLY_PID = 'b24d49-c71ee2';
    GTM_ID = 'GTM-M2NWS93';
    break;

  case 'qa':
    DOMAIN = 'tecnologia.ad.vagastec.com.br';
    SERVER_PORT = '9001';
    VACANCIES_API_BASE_URL = 'http://pesquisas.ad.vagastec.com.br/v1';
    TEC_VAGAS_URL = 'https://tecnologia.ad.vagastec.com.br';
    VAGAS_URL = 'https://qa-www.ad.vagastec.com.br';
    INFO_URL = 'https://info.vagas.com.br';
    PRIVALLY_PID = 'b24d49-e28f3c';
    GTM_ID = 'GTM-M3ZF4ZN';
    break;

  default:
    DOMAIN = 'localhost';
    SERVER_PORT = '3008';
    VACANCIES_API_BASE_URL = 'http://pesquisas.ad.vagastec.com.br/v1';
    TEC_VAGAS_URL = 'http://localhost:3008';
    VAGAS_URL = 'http://localhost:3008';
    INFO_URL = 'https://info.vagas.com.br';
    PRIVALLY_PID = 'b24d49-c71ee2';
    GTM_ID = 'GTM-M3ZF4ZN';
    break;
}

module.exports = {
  DOMAIN,
  SERVER_PORT,
  VACANCIES_API_BASE_URL,
  TEC_VAGAS_URL,
  VAGAS_URL,
  INFO_URL,
  DICAPRIO_VACANCIES_API_BASE_URL,
  PRIVALLY_PID,
  GTM_ID,
};
