import { createContext } from 'react';
const { VAGAS_URL } = require('../../env-config');

const categories = [
  { label: 'Back-end ', link: `${VAGAS_URL}/vagas-de-back-end` },
  { label: 'Front-end', link: `${VAGAS_URL}/vagas-de-front-end` },
  { label: 'FullStack', link: `${VAGAS_URL}/vagas-de-full-stack` },
  { label: 'Mobile', link: `${VAGAS_URL}/vagas-de-mobile` },
  { label: 'Devops', link: `${VAGAS_URL}/vagas-de-devops` },
  { label: 'UX Design', link: `${VAGAS_URL}/vagas-de-UX-design` },
  { label: 'UI Design', link: `${VAGAS_URL}/vagas-de-UI-design` },
  { label: 'Product Manager', link: `${VAGAS_URL}/vagas-de-Product-Manager` },
  { label: 'Growth Marketing', link: `${VAGAS_URL}/vagas-de-Growth` },
  { label: 'Marketing', link: `${VAGAS_URL}/vagas-de-marketing` },
  { label: 'SEO', link: `${VAGAS_URL}/vagas-de-seo` },
  { label: 'Inside Sales', link: `${VAGAS_URL}/vagas-de-inside-sales` },
  { label: 'Customer Success', link: `${VAGAS_URL}/vagas-de-Customer-Success` },
  { label: 'Executivo de Contas Hunter', link: `${VAGAS_URL}/vagas-de-hunter` },
  { label: 'Gerente de Relaciomento', link: `${VAGAS_URL}/vagas-de-gerente-de-relacionamento` },
  { label: 'Comercial', link: `${VAGAS_URL}/vagas-de-comercial` },
];

const cities = [
  { label: 'vagas Florianópolis', link: `${VAGAS_URL}/vagas-em-florianopolis` },
  { label: 'vagas Maringá', link: `${VAGAS_URL}/vagas-em-maringa` },
  { label: 'vagas São Paulo', link: `${VAGAS_URL}/vagas-em-sao-paulo` },
  { label: 'vagas Curitiba', link: `${VAGAS_URL}/vagas-em-curitiba` },
  { label: 'vagas Rio de Janeiro', link: `${VAGAS_URL}/vagas-em-rio-de-janeiro` },
  { label: 'vagas Porto Alegre', link: `${VAGAS_URL}/vagas-em-porto-alegre` },
  { label: 'vagas Belo Horizonte', link: `${VAGAS_URL}/vagas-em-belo-horizonte` },
  { label: 'vagas Aracaju', link: `${VAGAS_URL}/vagas-em-aracaju` },
];

const salaries = [
  { label: 'salário Front-end', link: `${VAGAS_URL}/cargo/desenvolvedor-front-end#salary` },
  { label: 'salário Full Stack', link: `${VAGAS_URL}/cargo/desenvolvedor-full-stack#salary` },
  { label: 'salário Desenvolvedor Mobile', link: `${VAGAS_URL}/cargo/desenvolvedor-mobile#salary` },
  { label: 'salário UX / UI Design', link: `${VAGAS_URL}/cargo/ux-ui-designer#salary` },
  { label: 'salário Product Manager', link: `${VAGAS_URL}/cargo/product-manager#salary` },
  { label: 'salário Marketing Digital', link: `${VAGAS_URL}/cargo/coordenador-de-marketing-digital#salary` },
  { label: 'salário SEO', link: `${VAGAS_URL}/cargo/analista-de-seo#salary` },
  { label: 'salário Inside Sales', link: `${VAGAS_URL}/cargo/inside-sales#salary` },
  { label: 'salário Customer Success', link: `${VAGAS_URL}/cargo/customer-success-manager#salary` },
  { label: 'salário Executivo de Contas Hunter', link: `${VAGAS_URL}/cargo/executivo-de-contas-hunter#salary` },
  { label: 'salário Gerente de Relacionamento', link: `${VAGAS_URL}/cargo/gerente-de-relacionamento-comercial#salary` },
];

const careersList = {
  types: [
    { label: 'Busca por categoria' },
    { label: 'Busca por cidade' },
    { label: 'Informações salariais' },
  ],
  links: {
    categories,
    cities,
    salaries,
  },
};

const careersContext = createContext(careersList);

export default careersContext;